<div *ngIf="!loading">
  <div class="dsc-banner-loader">
    <div class="dsc-banner-loader_content"></div>
    <div class="dsc-banner-loader_content_text">
        <div class="dsc-banner-loader_text" *ngFor="let item of this.totalBars;"></div>
        <div class="dsc-banner-loader_content_two"></div>
        <div class="dsc-banner-loader_text" *ngFor="let item of this.totalBars;"></div>
        <div class="dsc-banner-loader_content_two"></div>
        <div class="dsc-banner-loader_text" *ngFor="let item of this.totalBars;"></div>
    </div>
 </div>
</div>

<div class="modal_pm__terms" id="top-modal" *ngIf="loading">
  <div class="close-modal" >
    <span (click)="this.activeModal.close()"  class="close-icon">&times;</span>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="modal_pm__terms_circule">
          <img class="terms_img" [src]="terms.icon" alt="arrow" />
        </div>
      </div>
      <div class="col-md-12 text-center">
        <div class="modal_pm__terms_title">{{ terms.title }}</div>
      </div>
    </div>
  </div>

  <div class="container-terms modal_pm__cuerpo scrollable-content mockup-font" *ngIf="terms.type ==='acceptTermsConditios'">
      <p class="modal_pm__terms_subTitle">{{ terms.subTitleOne }}</p>
      <p class="modal_pm__terms_descriptions" [innerHTML]="terms.descriptonOne"></p>
      <p class="modal_pm__terms_subTitle">{{ terms.subTitleTwo }}</p>
      <p class="modal_pm__terms_descriptions" [innerHTML]="terms.descriptonTwo"></p>
      <p class="modal_pm__terms_subTitle">{{ terms.subTitleThree }}</p>
      <p class="modal_pm__terms_descriptions">{{ terms.descriptonThree }}</p>
      <p class="modal_pm__terms_subTitle">{{ terms.subTitleFour }}</p>
      <p class="modal_pm__terms_descriptions" [innerHTML]="terms.descriptonFour"></p>
      <p class="modal_pm__terms_subTitle">{{ terms.subTitleFive }}</p>
      <p class="modal_pm__terms_descriptions" [innerHTML]="terms.descriptonFive"></p>
      <p class="modal_pm__terms_subTitle">{{ terms.subTitleSix }}</p>
      <p class="modal_pm__terms_descriptions" [innerHTML]="terms.descriptonSix"></p>
  </div>
  
  <div class="container-terms modal_pm__cuerpo scrollable-content mockup-font" *ngIf="terms.type ==='acceptPersonalData'">
    <p class="modal_pm__terms_descriptions_default" [innerHTML]="terms.descriptonOne"></p>
  </div>

  <div class="col-md-12 text-center mb-4">
    <button type="button" class="btn btn-link modal_pm__terms_button" (click)="showResults(true,terms.type)">
      {{ terms.buttonName }}
    </button>
  </div>
</div>
