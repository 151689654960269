import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AssessCreditsExternalRequest,
  AssessCreditsExternalResponse,
  AssessCreditsResponse,
  AssessEligibilityExternalRequest,
  AssessEligibilityExternalResponse,
  AssessEligibilityResponse,
  IExternalUserFinancingForm,
  ILeadEligibilityRequest,
  ISavingsAccountParams,
  ISavingsAccountSolicitude,
  ISavingsAccountSolicitudeRejected,
  ITeaAndProductNameRequest,
  ITermAmountRequest,
  ITypeCardFinance,
  IdPreCreditRequest,
  PreCreditSeedBaseRequest,
  SucessFinancingSolicitudeRegister,
} from '@shared/interfaces';
import {
  IPrecreditState,
  digitalApprovedDependentModel,
  digitalApprovedIndependentModel,
  preapproveDependentModel,
  preapproveImageModel,
  preapproveIndependentModel,
} from '@shared/models/financing.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IUploadImageProgress } from '../interfaces/frontend/ui-components/progress-bar/index';
import { IUserFinancingForm } from '../models/UserProfileModel';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FinancingService {
  private readonly creditBase = `${environment.apiBaseUrl}/api-credit`;

  //** Financing form - preserving data
  public initialFormAmount!: number;
  public amountRequest!: number;
  public idPreCredit!: number;
  public typeCampaign!: number;
  public isExternalFinancing: boolean = false;
  public assessEligibilityExternalRequest!: AssessEligibilityExternalRequest;
  public assessEligibilityInternalRequest!: AssessEligibilityExternalRequest;
  public assessEligibilityExternalResponse!: AssessEligibilityExternalResponse;
  public assessCreditsExternalResponse!: AssessCreditsExternalResponse;

  public selectTypeCardStep!: ITypeCardFinance;

  public derivationSelected!: string;
  public typeCardSelected!: number;
  public typeDocument!: string;
  public documentNumber!: string;

  public selfieProgress: IUploadImageProgress = {};
  public documentsProgress: IUploadImageProgress[] = [];
  public documentsProgressShadow: IUploadImageProgress[] = [];

  public showSelfieError: boolean = false;
  public showDocumentError: boolean = false;

  public solicitudeId: string = '';

  public uploadedSelfie: string = '';
  public uploadedSelfieId: number = 0;
  public uploadedDocuments: any = [];

  public simulatorCompleted: boolean = false;
  public simulatorData: any = {};

  public aliado: string = '';
  public amountPreApproved!: number;

  public defaultRefersData: any = this.initRefersData();
  public refersData: any = this.defaultRefersData;

  //** Success solicitude information - preserving data
  public sucessFinancingSolicitudeRegister: SucessFinancingSolicitudeRegister = {
    name: '',
    fatherLastName: '',
    motherLastName: '',
    typeDocument: '',
    documentNumber: '',
  };

  private isIdPreCredit = new BehaviorSubject<number>(0);
  activeIdPreCredit = this.isIdPreCredit.asObservable();

  private stepGeneral = new BehaviorSubject<number>(0);
  activeStepGeneral = this.stepGeneral.asObservable();

  private formCompletedMessageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public formCompletedMessage$: Observable<string> = this.formCompletedMessageSubject.asObservable();
  public isLogged: boolean = false;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.loadLoggedUser();
  }

  private loadLoggedUser() {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLogged = true;
      }
    });
  }

  changeIdPreCredit(value: number) {
    this.isIdPreCredit.next(value);
  }

  changeStepGeneral(value: number) {
    this.stepGeneral.next(value);
  }

  //** Uploading documents */
  public getLastDocumentAdded(): IUploadImageProgress {
    let filtered = this.documentsProgress.filter((element) => element.progress == 0 && element.uploading == true)!;
    this.documentsProgressShadow.pop();
    return filtered[0];
  }

  public getImageProgress(index: number): IUploadImageProgress {
    const element = this.documentsProgress.find((element) => element.id === index);
    return element!;
  }

  public removeDocumentUploaded() {
    this.documentsProgress.splice(0, 1);
    this.documentsProgressShadow.splice(0, 1);
  }

  //** Internal financing */
  assessEligibility(assessEligibilityIN: AssessEligibilityExternalRequest): Observable<AssessEligibilityResponse> {
    const endpoint = `${this.creditBase}/intranet/eligibility?typeDocument=${assessEligibilityIN?.typeDocument}&documentNumber=${assessEligibilityIN?.documentNumber}&documentNumberConfirmation=${assessEligibilityIN?.documentNumberConfirmation}&phone=${assessEligibilityIN?.phone}&email=${assessEligibilityIN?.email}&chkPersonalData=${assessEligibilityIN?.chkPersonalData}&aliado=${assessEligibilityIN.aliado}&utmSource=${assessEligibilityIN.utmSource}&utmMedium=${assessEligibilityIN.utmMedium}&utmCampaign=${assessEligibilityIN.utmCampaign}&utmId=${assessEligibilityIN.utmId}
    `;
    return this.http.get<AssessEligibilityResponse>(endpoint);
  }

  assessCredits(amount: number,referredCode: string): Observable<AssessCreditsResponse> {
    const endpoint = `${this.creditBase}/intranet/typeCredit?amount=${amount}&referredCode=${referredCode}`;
    return this.http.get<AssessCreditsResponse>(endpoint);
  }

  createFinancingRequest(userFinancingForm: IUserFinancingForm) {
    const endpoint = `${this.creditBase}/intranet/solicitude`;
    return this.http.post(endpoint, userFinancingForm).pipe(
      catchError((err) => {
        console.error(err.message);
        return err.message;
      })
    );
  }

  createFinanceGeneralInternal(userFinancingForm: IUserFinancingForm) {
    const endpoint = `${this.creditBase}/intranet/solicitude`;
    return this.http.post(endpoint, userFinancingForm);
  }

  //** External financing */
  assessEligibilityExternal(
    assessEligibilityER: AssessEligibilityExternalRequest
  ): Observable<AssessEligibilityExternalResponse> {
    const endpoint = `${this.creditBase}/eligibility?typeDocument=${assessEligibilityER?.typeDocument}&documentNumber=${assessEligibilityER?.documentNumber}&documentNumberConfirmation=${assessEligibilityER?.documentNumberConfirmation}&phone=${assessEligibilityER?.phone}&email=${assessEligibilityER?.email}&chkPersonalData=${assessEligibilityER?.chkPersonalData}&aliado=${assessEligibilityER.aliado}&utmSource=${assessEligibilityER.utmSource}&utmMedium=${assessEligibilityER.utmMedium}&utmCampaign=${assessEligibilityER.utmCampaign}&utmId=${assessEligibilityER.utmId}
    `;

    return this.http.get<AssessEligibilityExternalResponse>(endpoint);
  }

  assessEligibilityExternalYape(request: any): Observable<any> {
    const endpoint = `${this.creditBase}/yapeEligibility`;
    return this.http.post(endpoint, request);
  }

  assessEligibilityExternalSeed(request: any): Observable<any> {
    const endpoint = `${this.creditBase}/seedEligibility`;
    return this.http.post(endpoint, request);
  }

  assessCreditsExternal(
    assessCreditsExternalRequest: AssessCreditsExternalRequest,referredCode: string
  ): Observable<AssessCreditsExternalResponse> {
    const endpoint = `${this.creditBase}/typeCredit?idPreCredit=${assessCreditsExternalRequest.idPreCredit}&amount=${assessCreditsExternalRequest.amount}&referredCode=${referredCode}`;

    return this.http.get<AssessCreditsExternalResponse>(endpoint);
  }

  createFinancingExternalRequest(userFinancingForm: IExternalUserFinancingForm) {
    const endpoint = `${this.creditBase}/solicitude`;
    return this.http.post(endpoint, userFinancingForm).pipe(
      catchError((err) => {
        return err.message;
      })
    );
  }

  createFinanceGeneralExternal(userFinancingForm: IExternalUserFinancingForm) {
    const endpoint = `${this.creditBase}/solicitude`;
    return this.http.post(endpoint, userFinancingForm);
  }

  clearAssessEligibilityExternalRequest() {
    this.assessEligibilityExternalRequest = this.initAssessEligibilityExternalRequestObject();
  }

  clearInitAssessCardTypeRequestObject() {
    this.selectTypeCardStep = this.initAssessCardTypeRequestObject();
  }

  public initAssessCardTypeRequestObject() {
    return {
      seed: false,
      general: false,
      preapprove: false,
      digital: false,
    };
  }

  public initAssessEligibilityExternalRequestObject(): AssessEligibilityExternalRequest {
    return this.getEmptyRequestObject();
  }

  public initAssessEligibilityInternalRequestObject(): AssessEligibilityExternalRequest {
    return this.getEmptyRequestObject();
  }

  public getEmptyRequestObject() {
    return {
      typeDocument: '',
      documentNumber: '',
      documentNumberConfirmation: '',
      phone: '',
      email: '',
      chkPersonalData: false,
      aliado: '',
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
      utmId: '',
      amount: 0,
    };
  }

  callNegativeApiValidation(request: ILeadEligibilityRequest): Observable<any> {
    let endpoint = `${this.creditBase}/leadEligibility?typeDocument=${request?.typeDocument}&documentNumber=${request?.documentNumber}&phone=${request?.phone}&email=${request?.email}`;
    if (request?.aliado) endpoint += `&aliado=${request.aliado}`;
    if (request?.utmId) endpoint += `&utmId=${request.utmId}`;
    if (request?.utmMedium) endpoint += `&utmMedium=${request.utmMedium}`;
    if (request?.utmSource) endpoint += `&utmSource=${request.utmSource}`;
    return this.http.get<any>(endpoint);
  }

  getGeneralFormParams(): Observable<any> {
    const endpoint = `${this.creditBase}/param/general`;
    return this.http.get(endpoint);
  }

  getMaritalStatusRemarketingGeneral() {
    const endpoint = `${this.creditBase}/param/general`;
    return this.http.get(endpoint);
  }

  getPreapprovedParams() {
    const endpoint = `${this.creditBase}/param/preapproved`;
    return this.http.get(endpoint);
  }

  //* PREAPPROVED SERVICES */
  postPreapproveInternalDependent(params: preapproveDependentModel): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/solicitude/preapproved`;
    return this.http.post(endpoint, params);
  }

  postPreapproveInternalIndependent(params: preapproveIndependentModel): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/solicitude/preapproved`;
    return this.http.post(endpoint, params);
  }

  postPreapproveExternalDependent(params: preapproveDependentModel): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/preapproved`;
    return this.http.post(endpoint, params);
  }

  putPreapprove(params: preapproveIndependentModel | preapproveDependentModel, solicitudeId: string): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/preapproved/${solicitudeId}`;
    return this.http.put(endpoint, params);
  }

  postPreapproveExternalIndependent(params: preapproveIndependentModel): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/preapproved`;
    return this.http.post(endpoint, params);
  }

  postPreapproveFiles(file: preapproveImageModel, idSolicitude: string): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/${idSolicitude}/upload`;
    return this.http.post(endpoint, file, { reportProgress: true, observe: 'events', responseType: 'json' });
  }

  deleteImage(solicitudeId: number): Observable<boolean> {
    return this.http
      .request('DELETE', `${this.creditBase}/solicitude/file/${solicitudeId}`, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(mapTo(true));
  }

  //* CDE SERVICES */
  postCDEInternalDependent(params: preapproveDependentModel): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/solicitude/cde`;
    return this.http.post(endpoint, params);
  }

  postCDEInternalIndependent(params: preapproveIndependentModel): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/solicitude/cde`;
    return this.http.post(endpoint, params);
  }

  postCDEExternalIndependent(params: preapproveIndependentModel): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/cde`;
    return this.http.post(endpoint, params);
  }

  postCDEExternalDependent(params: preapproveDependentModel): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/cde`;
    return this.http.post(endpoint, params);
  }

  putCDE(params: preapproveIndependentModel | preapproveDependentModel, solicitudeId: string): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/cde/${solicitudeId}`;
    return this.http.put(endpoint, params);
  }
  
  //* FIRST CREDIT SERVICES */
  postFirstCreditInternalIndependent(params: any): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/solicitude/firstcredit`;
    return this.http.post(endpoint, params);
  }

  postFirstCreditExternalIndependent(params: any): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/firstcredit`;
    return this.http.post(endpoint, params);
  }

  putFirstCredit(params: any, solicitudeId: string): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/firstcredit/${solicitudeId}`;
    return this.http.put(endpoint, params);
  }

  completeSolicitudeFirstCredit(idSolicitude: string) {
    const endpoint = `${this.creditBase}/solicitude/complete/${idSolicitude}`;
    return this.http.put(endpoint, null);
  }

  //* DIGITAL APPROVED SERVICES */
  postDigitalApprovedInternal(
    params: digitalApprovedDependentModel | digitalApprovedIndependentModel
  ): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/solicitude/digitalapproved`;
    return this.http.post(endpoint, params);
  }
  postDigitalApprovedExternal(
    params: digitalApprovedDependentModel | digitalApprovedIndependentModel
  ): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/digitalapproved`;
    return this.http.post(endpoint, params);
  }

  putDigitalApproved(
    params: digitalApprovedDependentModel | digitalApprovedIndependentModel,
    solicitudeId: string
  ): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/digitalapproved/${solicitudeId}`;
    return this.http.put(endpoint, params);
  }

  getDigitalApprovedParams() {
    const endpoint = `${this.creditBase}/param/digitalapproved`;
    return this.http.get(endpoint);
  }

  postDigitalApprovedFiles(file: preapproveImageModel, idSolicitude: string): Observable<any> {
    return this.postPreapproveFiles(file, idSolicitude);
  }

  completeSolicitude(idSolicitude: string) {
    const endpoint = `${this.creditBase}/solicitude/complete/${idSolicitude}`;
    return this.http.put(endpoint, null);
  }

  //* ENTREPRENEUR WOMAN SERVICES */
  getEntrepreneurWomanParams() {
    const endpoint = `${this.creditBase}/param/womancredit`;
    return this.http.get(endpoint);
  }

  postEntrepreneurWomanSolicitude(params: any): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/solicitude/womancredit`;
    return this.http.post(endpoint, params);
  }

  //** Success request information */
  setSuccessInformation(name: string = '', fatherLastName: string = '', motherLastName: string = '') {
    this.sucessFinancingSolicitudeRegister.name = name;
    this.sucessFinancingSolicitudeRegister.fatherLastName = fatherLastName;
    this.sucessFinancingSolicitudeRegister.motherLastName = motherLastName;
  }

  //** Clear uploaded files */
  clearUploadedFiles() {
    this.uploadedSelfie = '';
    this.uploadedSelfieId = 0;
    this.uploadedDocuments = [];
  }

  //** INCOMPLETED STEPS */
  getIdPreCredit(body: IdPreCreditRequest): Observable<any> {
    const endpoint = `${this.creditBase}/precredit`;
    return this.http.post(endpoint, body);
  }

    //** INCOMPLETED SEED STEPS 0 */
    getIdPreCreditSeed(body: PreCreditSeedBaseRequest): Observable<any> {
      const endpoint = `${this.creditBase}/precredit/seed`;
      return this.http.post(endpoint, body);
    }

  updatePrecreditState(idPreCredit: string, params: IPrecreditState): Observable<any> {
    const endpoint = `${this.creditBase}/precredit/${idPreCredit}`;
    return this.http.put(endpoint, params);
  }

  deletePrecredit(idPreCredit: string) {
    const endpoint = `${this.creditBase}/precredit/${idPreCredit}`;
    return this.http.delete(endpoint);
  }

  //** SAVINGS ACCOUNT */
  savingsAccountIsEligible(urlData: ISavingsAccountParams): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/account/eligible`;
    return this.http.post(endpoint, urlData);
  }
  sendSavingsAccountSolicitude(solicitude: ISavingsAccountSolicitude): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/account`;
    return this.http.post(endpoint, solicitude);
  }
  sendSavingsAccountSolicitudRejected(solicitude: ISavingsAccountSolicitudeRejected): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/account/reject`;
    return this.http.post(endpoint, solicitude);
  }

  //** SIMULATOR */
  getTeaAndProductName(body: ITeaAndProductNameRequest): Observable<any> {
    const endpoint = `${this.creditBase}/simulator/tea`;
    return this.http.post(endpoint, body);
  }

  calculateTermAmount(body: ITermAmountRequest, recaptchaToken: string): Observable<any> {
    const endpoint = `${this.creditBase}/simulator/termAmount`;
    return this.http.post(endpoint, body, {
      headers: new HttpHeaders({ 're-captcha-token': recaptchaToken }),
    });
  }

  calculateTermAmountLogged(body: ITermAmountRequest, recaptchaToken: string): Observable<any> {
    const endpoint = `${this.creditBase}/intranet/simulator/termAmount`;
    return this.http.post(endpoint, body, {
      headers: new HttpHeaders({ 're-captcha-token': recaptchaToken }),
    });
  }

  // PRE FIIL FORM

  getPreFillFormAllies(typeDocument: string, documentNumber: string, formType: string): any {
    const endpoint = `${this.creditBase}/solicitude/latest`;
    return this.http.get(
      `${endpoint}?typeDocument=${typeDocument}&documentNumber=${documentNumber}&formType=${formType}`
    );
  }

  getPreFillSemilla(): any {
    return this.http.get(`${this.creditBase}/intranet/lead/latest`);
  }

  getPreFillUber(typeDocument: string, documentNumber: string): any {
    const endpoint = `${this.creditBase}/solicitude/uber/latest`;
    return this.http.get(`${endpoint}?typeDocument=${typeDocument}&documentNumber=${documentNumber}`);
  }

  // NEW FLOW YAPE
  sendSolitudeYape(body: any): Observable<any> {
    const endpoint = `${this.creditBase}/solicitude/yape`;
    return this.http.post(endpoint, body);
  }

  getAgencyGeneral(departmentId: number, provinceId: number, districtId: number): Observable<any> {
    ///api-credit/agencies?countryId=01&departmentId=1&provinceId=101&districtId=10101
    const endpoint = `${this.creditBase}/agencies?countryId=01&departmentId=${departmentId}&provinceId=${provinceId}&districtId=${districtId}`;
    return this.http.get(endpoint);
  }

  setFormCompletedMessage(value: string): void {
    this.formCompletedMessageSubject.next(value);
  }

  cleanFormCompletedMessage(): void {
    this.formCompletedMessageSubject.next('');
  }

  clearRefersData(){
    this.defaultRefersData = this.initRefersData();
    this.refersData = this.initRefersData();
  }

  initRefersData() {
    return {
      referredCode:'',
      idPreCredit: 0,
    }
  }

  setRefersData() {
    this.refersData = this.defaultRefersData;
  }
}
