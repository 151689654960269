import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BANNER_VALUES } from '@shared/constants/dashboard.constants';

@Component({
  selector: 'yevo-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent implements OnInit {
  @Output() loadAlert: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.loadCalendar();
  }

  loadCalendar(): void {
    this.loadAlert.emit(true);
  }
  goToLink(){
    this.router.navigate([BANNER_VALUES.urlCalculateSeed]);
  }
}
