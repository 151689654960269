import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ReferredProgram, ReferredRegisterRequest, ReferredRegisterResponse, ReferredResponse, createReferredRequest } from '@shared/interfaces/frontend/recommend';

@Injectable({
  providedIn: 'root',
})
export class RecommendService {

    private readonly contentManagerApi = `${environment.apiBaseUrl}/api-credit/`;

    constructor(private httpClient: HttpClient) {}

    headerReCaptcha(reCaptcha: string): any {
      return new HttpHeaders({ 're-captcha-token': reCaptcha });
    }

    registerRecommendProgram(data: ReferredRegisterRequest,recaptcha:string): Observable<ReferredRegisterResponse> {
      return this.httpClient.post<ReferredRegisterResponse>(`${this.contentManagerApi}referred-program/register`, data,{
        headers: this.headerReCaptcha(recaptcha!)});
    }
  
    getRecommendProgramByUserId(id: number): Observable<ReferredProgram> {
      return this.httpClient.get<ReferredProgram>(`${this.contentManagerApi}referred-program/user/${id}`);
    }
  
    createRecommend(data:createReferredRequest): Observable<string> {
      return this.httpClient.post<string>(`${this.contentManagerApi}referred-program/referred`, data);
    }
  
    getRecommendProgramByUser(id: number): Observable<ReferredResponse> {
    return this.httpClient.get<ReferredResponse>(`${this.contentManagerApi}referred-program/referred/${id}`);
    }

    updateRecommendProgramByUserModal(userId: number, modalView: number): Observable<string> {
      return this.httpClient.post<string>(`${this.contentManagerApi}referred-program/referred/update`, { userId, modalView });
    }
    
}

