
<div class="modal-share-header">

    <div class="icon-close-mobile" (click)="activeModal.close(null)">
        <img src="/assets/img/intranet/refers/close-small.png" alt="">
    </div>

    <img  src="/assets/img/intranet/refers/banner-requisitos.png" class="imagen-b" alt="">

    <h4 class="modal-share-title">Antes de continuar, <br>
        toma en cuenta lo siguiente</h4>
</div>
<div class="modal-body-get">
    <div class="card-shar-form">

        <p class="p-text">Se sugiere que tus recomendados:</p>

        <div class="d-flex">
            <img class="img-card" src="/assets/img/intranet/refers/documento.png" alt="Paso 2:">
            <p>
                Sean <strong>mayores de edad y no sean clientes vigentes de Mibanco.</strong> 
            </p>
        </div>
        <div class="d-flex">
            <img class="img-card" src="/assets/img/intranet/refers/casa.png" alt="Paso 2:">
            <p>
                Cuenten con <strong>vivienda propia.</strong> 
            </p>
        </div>
        <div class="d-flex">
            <img  class="img-card" src="/assets/img/intranet/refers/negocio.png" alt="Paso 2:">
            <p>
                Tengan una <strong>fuente de ingresos estable</strong>  en su ocupación o negocio.
            </p>
        </div>
        <div class="d-flex">
            <img class="img-card" src="/assets/img/intranet/refers/papeles.png" alt="Paso 2:">
            <p class="p-mob-tex">
                Tengan una <strong>buena salud crediticia</strong> en el sistema financiero.
            </p>
        </div>
        <div class="register__submit">
            <button 
            class="btn-active button-desktop"
            (click)="activeModal.close(true)">Entendido</button>

            <div class="floating-button-mobile">
                    <button type="button" class="btn-active"
                    (click)="activeModal.close(true)">
                      <span>Entendido</span>
                    </button>
            </div>
        </div>
    </div>
</div>