<div
  *ngIf="!isAmountFormVisible && isLogged && isAllyBannerVisible && allyBannerIdentifier"
  class="d-block d-lg-none container mb-0 col-12 p-0"
>
  <ngx-skeleton-loader
    *ngIf="isLogged && !isAmountFormVisible && !isExternalFinancing && !isUnsuccessMessageVisible"
    [appearance]="'line'"
    count="1"
    animation="pulse"
    [theme]="{
      'background-color': '#F5F5F5',
      width: '100%',
      height: '260px',
      'border-radius': '16px',
      'margin-bottom': '1rem',
      'padding-bottom': '1rem'
    }"
  >
  </ngx-skeleton-loader>
</div>
<div
  class="col-12 breadcrumb breadcrumb-desktop d-block d-lg-none"
  id="formAmountBreadcrumbs"
>
  <div
    class="container col-12 p-0"
    [ngClass]="isAmountFormVisible && !aliado && !isUnsuccessMessageVisible ? 'd-none' : ''"
  >
    <div class="div_bea" [ngClass]="isShowUnsuccessMessage ? 'd-none' : ''">
      <yevo-breadcrumb [breadcrumbs]="breadcrumbs" class="col-12"></yevo-breadcrumb>
    </div>
  </div>
</div>
<div *ngIf="isAmountFormVisible && !aliado" class="d-block d-lg-none container mb-0 col-12 p-0">
  <yevo-financing-ally-landing
    [identifier]="allyBannerIdentifier"
    [isAmountFormVisible]="isAmountFormVisible"
  ></yevo-financing-ally-landing>
</div>
<div
  class="container-fluid mt-0 h-100 position-relative image-woman"
  [ngClass]="{ 'd-none': !isLogged && isFirstViewLoading, 'message-send-mobile': isUnsuccessMessageVisible }"
>
  <div class="row hidden" *ngIf="!isUnsuccessMessageVisible">
    <div class="col-5 p-0 d-none d-lg-block" *ngIf="isAmountFormVisible && !isYape">
      <yevo-financing-ally-landing
        *ngIf="isAllyBannerVisible"
        [identifier]="allyBannerIdentifier"
        [isAmountFormVisible]="isAmountFormVisible"
      ></yevo-financing-ally-landing>
    </div>
    <div
      class="col-5 p-0 d-none d-lg-block"
      *ngIf="!isAmountFormVisible && isLogged && isAllyBannerVisible && allyBannerIdentifier"
    >
      <ngx-skeleton-loader
        *ngIf="isLogged && !isAmountFormVisible && !isExternalFinancing && !isUnsuccessMessageVisible"
        [appearance]="'line'"
        count="1"
        animation="pulse"
        [theme]="{
          'background-color': '#F5F5F5',
          width: '100%',
          height: '600px',
          'border-radius': '16px',
          'margin-bottom': '1rem',
          'padding-bottom': '1rem'
        }"
      >
      </ngx-skeleton-loader>
    </div>
    <div
      class="col-12 col-lg-7"
      [ngClass]="isAllyBannerVisible ? 'd-flex flex-column justify-content-center' : 'col-lg-12 mb-3'"
    >
      <!--DESKTOP GO BACK IS LOGGED-->
      <ng-template [ngIf]="isLogged && isAmountFormVisible">
        <div
          class="col-12 breadcrumb breadcrumb-desktop d-none d-lg-block ml-3"
          *ngIf="isAmountFormVisible && !isExternalFinancing"
        >
          <div class="div_bea">
            <yevo-breadcrumb [breadcrumbs]="breadcrumbs" class="col-12"></yevo-breadcrumb>
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="isLogged && !isAmountFormVisible">
        <ngx-skeleton-loader
          *ngIf="isLogged && !isAmountFormVisible && !isExternalFinancing && !isUnsuccessMessageVisible"
          [appearance]="'line'"
          count="1"
          animation="pulse"
          class="d-none d-lg-block"
          [theme]="{
            'background-color': ' #f5f5f5',
            width: '120px',
            height: '30px',
            'border-radius': '16px',
            'margin-top': '1rem'
          }"
        ></ngx-skeleton-loader>
      </ng-template>
      <!--END DESKTOP GO BACK IS LOGGED-->

      <ng-container class="position-relative">
        <div class="container d-block" [ngClass]="isLogged ? 'mt-4 d-lg-none' : 'mt-0 mt-md-2'">
          <div class="row">
            <!--MOBILE GO BACK IS LOGGED-->
            <div class="col-md-12 pl-0" *ngIf="isLogged">
              <ngx-skeleton-loader
                *ngIf="isLogged && !isAmountFormVisible && !isUnsuccessMessageVisible"
                [appearance]="'line'"
                count="1"
                animation="pulse"
                class="d-block d-lg-none"
                [theme]="{
                  'background-color': ' #f5f5f5',
                  width: '120px',
                  height: '30px',
                  'border-radius': '16px',
                  'margin-top': '1rem'
                }"
              ></ngx-skeleton-loader>
            </div>
            <!--END MOBILE GO BACK IS LOGGED-->
            <div class="col-12 ml-3 breadcrumb breadcrumb-desktop d-none d-lg-block">
              <div class="div_bea">
                <yevo-breadcrumb [breadcrumbs]="breadcrumbs" class="col-12"></yevo-breadcrumb>
              </div>
            </div>
          </div>
        </div>
        <div class="centered-container">
          <h1 class="title black-color text-left text-sm-center mt-3 pr-5 pr-sm-0" *ngIf="isAmountFormVisible">
            ¿Cuánto es el monto que deseas solicitar?
          </h1>
          <ngx-skeleton-loader
            *ngIf="isLogged && !isAmountFormVisible && !isExternalFinancing && !isUnsuccessMessageVisible"
            [appearance]="'line'"
            count="1"
            animation="pulse"
            [theme]="{
              'background-color': '#F5F5F5',
              width: '100%',
              height: '60px',
              'border-radius': '16px',
              'margin-bottom': 0,
              'padding-bottom': 0
            }"
          >
          </ngx-skeleton-loader>
          <p class="text-sm-center black-color mount" *ngIf="isAmountFormVisible">
            Escribe el monto que deseas solicitar💰
          </p>
          <ngx-skeleton-loader
            *ngIf="isLogged && !isAmountFormVisible && !isExternalFinancing && !isUnsuccessMessageVisible"
            [appearance]="'line'"
            count="1"
            animation="pulse"
            [theme]="{
              'background-color': '#F5F5F5',
              width: '100%',
              height: '24px',
              'border-radius': '16px',
              'margin-bottom': '1rem',
              'padding-bottom': '1rem'
            }"
          >
          </ngx-skeleton-loader>
          <div class="form-group" *ngIf="isAmountFormVisible">
            <div class="input-icon">
              <input
                currencyMask
                #ctrl
                name="ctrl"
                class="my-3"
                type="tel"
                inputmode="numeric"
                [(ngModel)]="amountRequestedClient"
                (ngModelChange)="changeAmountRequest($event)"
                [readonly]="!isAmountEditable || isLoading"
                placeholder="S/ 0"
                maxlength="7"
                required
                (click)="!isAmountEditable && sendAmountRequested()"
              />
              <i *ngIf="amountRequested" [ngStyle]="styleIconoInput()">S/</i>
            </div>
            <div *ngIf="amountRequested >= amountRequestedMax">
              <p class="amount-max">¡Recuerda! El monto máximo es S/ 250,000</p>
            </div>
          </div>
          <ngx-skeleton-loader
            *ngIf="isLogged && !isAmountFormVisible && !isExternalFinancing && !isUnsuccessMessageVisible"
            [appearance]="'line'"
            count="1"
            animation="pulse"
            [theme]="{
              'background-color': '#F5F5F5',
              width: '100%',
              height: '75px',
              'border-radius': '16px',
              'margin-bottom': '1rem',
              'padding-bottom': '1rem'
            }"
          >
          </ngx-skeleton-loader>

          <yevo-button
            *ngIf="isAmountFormVisible"
            (buttonClick)="sendAmountRequested()"
            [disabled]="!isChecked || (!isValid && isAmountEditable === true) || isLoading"
            [className]="!isAmountEditable ? 'link' : 'primary'"
            [radius]="true"
            >{{ submitButtonLabel }}
            <img src="/assets/icons/edit-2.svg" alt="pencil" class="image-edit" *ngIf="!isAmountEditable" />
          </yevo-button>
          <ngx-skeleton-loader
            *ngIf="isLogged && !isAmountFormVisible && !isExternalFinancing && !isUnsuccessMessageVisible"
            [appearance]="'line'"
            count="1"
            animation="pulse"
            [theme]="{
              'background-color': '#F5F5F5',
              width: '100%',
              height: '45px',
              'border-radius': '16px',
              'margin-bottom': 0,
              'padding-bottom': 0
            }"
          >
          </ngx-skeleton-loader>
          <!-- END: Congrats message -->
          <!-- We're sorry message -->
          <div *ngIf="isLowAmountMessageVisible" class="apologize-msg-container mt-3">
            <div class="d-flex justify-content-center my-sm-5">
              <img src="/assets/img/intranet/financing/green-email.png" alt="sobre de correo color verde" />
            </div>
            <p class="bold green-color text-center my-3">¡Lo sentimos!</p>
            <p class="text-center black-color">
              El monto que necesitas es muy pequeño para acceder a un crédito.
              <br />
              Monto mínimo <span class="bold green-color">S/ {{ minimumAmountAllowed }}</span>
            </p>
          </div>
          <!-- END: We're sorry message -->
        </div>

        <div class="mt-4" *ngIf="isChecked && (isValid || isAmountEditable !== true) && isAmountEditable && aliado !== 'miprimercredito'">
          <yevo-financing-loan-simulator-banner from="Monto a solicitar"></yevo-financing-loan-simulator-banner>
        </div>

        <!-- Cards container -->
        <div class="cards-container mt-4 mt-sm-4 d-xl-flex flex-column flex-xl-wrap mx-xl-auto d-flex flex-nowrap">
          <p *ngIf="isOkAmountMessageVisible" class="congratulation">¡Felicidades! 🎉</p>
          <p *ngIf="isOkAmountMessageVisible" class="congrats-msg-container mt-sm-3 text-center">
            <span class="options-main-label">Tenemos estas opciones para ti 👇🏼</span>
          </p>

          <div class="card-container mx-auto my-3 order-{{ isRecommendedCardOrder }}" *ngIf="isRecommendedCardVisible">
            <yevo-loan-request-card
              [cardInfo]="recomendCardInfo"
              [type]="'recommended'"
              (cardClicked)="handleRecommendedCardClick($event)"
              [aroundBorder]="true"
            ></yevo-loan-request-card>
          </div>

          <div *ngIf="isEvaluationCardVisible" class="card-container mx-auto my-3 order-{{ isEvaluationCardOrder }}">
            <yevo-loan-request-card
              [cardInfo]="evaluationCardInfo"
              [type]="'evaluation'"
              (cardClicked)="handleEvaluationCardClick($event)"
              [aroundBorder]="true"
            ></yevo-loan-request-card>
          </div>

          <div *ngIf="isSecondProvisionVisible" class="card-container mx-auto my-3 order-{{ isSecondProvisionOrder }}">
            <yevo-loan-request-card
              [cardInfo]="approvedSecondProvision"
              [type]="'second-provision'"
              (cardClicked)="handleEvaluationCardClick($event)"
              [aroundBorder]="true"
            ></yevo-loan-request-card>
          </div>

          <div *ngIf="isCDECardVisible" class="card-container mx-auto my-3 order-{{ isCDECardOrder }}">
            <yevo-loan-request-card
              [cardInfo]="CDECardInfo"
              [type]="'cde'"
              (cardClicked)="handleCDECardClick($event)"
              [aroundBorder]="true"
            ></yevo-loan-request-card>
          </div>

          <div *ngIf="isSeedCardVisible" class="card-container mx-auto my-3 order-{{ isSeedCardOrder }}">
            <yevo-loan-request-card
              [cardInfo]="seedCardInfo"
              [type]="'seed'"
              (cardClicked)="handleSeedCardClick($event)"
              [aroundBorder]="true"
            ></yevo-loan-request-card>
          </div>

          <div *ngIf="isKushkaCardVisible" class="card-container mx-auto my-3 order-{{ isKushkaCardOrder }}">
            <yevo-loan-request-card
              [cardInfo]="kushkaCardInfo"
              [type]="'evaluation'"
              (cardClicked)="handleKushkaCardClick($event)"
              [aroundBorder]="true"
            ></yevo-loan-request-card>
          </div>

          <div
            *ngIf="isDigitalApprovedCardVisible"
            class="card-container mx-auto my-3 order-{{ isDigitalApprovedCardOrder }}"
          >
            <yevo-loan-request-card
              [cardInfo]="digitalApprovedCardInfo"
              [type]="'cde'"
              (cardClicked)="handleDigitalApprovedCardClick($event)"
              [aroundBorder]="true"
            ></yevo-loan-request-card>
          </div>

          <div
            *ngIf="isFirstCreditCardVisible"
            class="card-container mx-auto my-3 order-{{ isFirstCreditCardOrder }}"
          >
            <yevo-loan-request-card
              [cardInfo]="firstCreditCardInfo"
              [type]="'first-credit'"
              (cardClicked)="handleFirstCreditCardClick($event)"
              [aroundBorder]="true"
            ></yevo-loan-request-card>
          </div>
        </div>
        <!-- END: Cards container -->
        <div class="centered-container">
          <p
            *ngIf="
              isSeedCardVisible ||
              isEvaluationCardVisible ||
              isRecommendedCardVisible ||
              isKushkaCardVisible ||
              isDigitalApprovedCardVisible
            "
            class="disclaimer-msg mb-3"
          >
            Este canal está habilitado solo para personas que no presentan relación alguna con PEP (Persona expuesta
            políticamente), ni empresas dirigidas por este tipo de persona. De ser tu caso por favor acércate a la
            agencia más cercana.
          </p>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="isUnsuccessMessageVisible">
    <yevo-financing-request-message
      *ngIf="unsuccessMessageType == unsuccessMessageTypeList.SENT_SOLICITUDE_CODE"
      [messageType]="'already-sent'"
      [isLogged]="isLogged"
      [typeForm]="typeForm"
    ></yevo-financing-request-message>
    <yevo-financing-request-message
      *ngIf="
        unsuccessMessageType == unsuccessMessageTypeList.NEGATIVE_BASE_CODE ||
        unsuccessMessageType == unsuccessMessageTypeList.HIGH_RISK_CODE
      "
      [isLogged]="isLogged"
      [unsuccess]="true"
    ></yevo-financing-request-message>
  </ng-container>

  <div
    *ngIf="!isAllyBannerVisible && !isLoggedValidate"
    class="d-none d-lg-block"
    [ngClass]="allyBannerIdentifier ? 'chico_alicorp' : 'chico_alicorp_notBanner'"
  >
    <img src="/assets/img/chico_alicorp.png" alt="chico" />
  </div>
  <div
    *ngIf="!isAllyBannerVisible && !isLoggedValidate"
    class="d-none d-lg-block"
    [ngClass]="{
      chica_alicorp:
        allyBannerIdentifier ||
        isRecommendedCardVisible ||
        isEvaluationCardVisible ||
        isSeedCardVisible ||
        isKushkaCardVisible,
      chica_alicorp_notBanner: !allyBannerIdentifier,
      chica_alicorp_notBanner_simulator: isChecked && (isValid || isAmountEditable !== true) && isAmountEditable
    }"
  >
    <img src="/assets/img/chica_alicorp1.png" alt="chica" />
  </div>

  <div
    *ngIf="!isAllyBannerVisible && isLoggedValidate && isEligible"
    class="d-none d-lg-block"
    [ngClass]="allyBannerIdentifier ? 'chico_alicorp' : 'chico_alicorp_notBanner'"
  >
    <img src="/assets/img/chico_alicorp.png" alt="chico" />
  </div>
  <div
    *ngIf="!isAllyBannerVisible && isLoggedValidate && isEligible"
    class="d-none d-lg-block"
    [ngClass]="{
      chica_alicorp:
        allyBannerIdentifier ||
        isRecommendedCardVisible ||
        isEvaluationCardVisible ||
        isSeedCardVisible ||
        isKushkaCardVisible,
      chica_alicorp_notBanner: !allyBannerIdentifier,
      chica_alicorp_notBanner_simulator: isChecked && (isValid || isAmountEditable !== true) && isAmountEditable
    }"
  >
    <img src="/assets/img/chica_alicorp1.png" alt="chica" />
  </div>
</div>

<!-- MODAL DISCLAIMER PREAPPROVED -->
<ng-template #modalDisclaimerContent let-modal class="modal-content-disclaimer">
  <div class="modal-header pt-4 pb-3">
    <button type="button" class="close" (click)="dismissDisclaimerPreapprovedModal()">
      <img class="images__clouds" alt="close-small-img" src="/assets/img/close-small.svg" />
    </button>
  </div>
  <div class="modal-body mb-5 mx-2">
    <div class="d-flex justify-content-center mb-3">
      <img
        class="img-fluid"
        alt="disclaimer-preapproved-img"
        src="/assets/img/financing/disclaimer-preapproved.svg"
        width="114"
        height="113"
      />
    </div>
    <h2 class="text-center title">AVISO OBLIGATORIO</h2>
    <div class="modal-description mb-3 text-center description">
      Para continuar con tu solicitud, debes encontrarte en tu local de negocio y tener a la mano tus boletas de compra
      o venta.
    </div>
    <div class="w-100">
      <div class="register__submit text-center">
        <button type="button" class="btn btn-primary" (click)="closeDisclaimerPreapprovedModal()">Comenzar</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- MODAL DISCLAIMER PREAPPROVED -->
<ng-template #modalDisclaimerContentNotice let-modal class="modal-content-disclaimer">
  <div class="modal-header pt-4 pb-3">
    <button type="button" class="close" (click)="dismissNoticePreapprovedModal()">
      <img class="images__clouds" alt="close-small-img" src="/assets/img/close-small.svg" />
    </button>
  </div>
  <div class="modal-body mb-2">
    <div class="d-flex justify-content-center mb-3">
      <img
        class="img-fluid"
        alt="disclaimer-preapproved-img"
        src="/assets/financing/notice.png"
        width="114"
        height="113"
      />
    </div>
    <h2 class="text-center modal-title-notice">¡AVISO IMPORTANTE!</h2>
    <div class="modal-description mb-3 text-center modal-description-notice">
      Necesitas continuar tu solicitud desde <span>tu celular</span>, para que puedas adjuntar las fotos que te
      solicitemos y validar la existencia de tu negocio.
    </div>
    <div class="w-100">
      <div class="register__submit text-center">
        <button type="button" class="btn btn-primary" (click)="dismissNoticePreapprovedModal()">Regresar</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- MODAL UBER -->
<ng-template #modalUberContent let-modal class="modal-uber-content">
  <div class="modal-header pt-4 pb-3">
    <button type="button" class="close" (click)="dismissUberModal()">
      <img class="images__clouds" alt="close-small-img" src="/assets/img/close-small.svg" />
    </button>
  </div>
  <div class="modal-body mb-5 mx-2">
    <div class="d-flex justify-content-center mb-3">
      <img
        class="img-fluid"
        alt="disclaimer-preapproved-img"
        src="/assets/img/financing/uber-modal-img.png"
        width="114"
        height="113"
      />
    </div>
    <h2 class="text-center title">¡ATENCIÓN!</h2>
    <div class="modal-description mb-3 text-center uber-description">
      Te redirigiremos a iniciar sesión en Uber y continuaremos con tu solicitud.
    </div>
    <div class="w-100">
      <div class="register__submit text-center">
        <button type="button" class="btn btn-primary btn-uber-modal" (click)="closeUberModal()">Continuar</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- MODAL ENTREPRENEUR WOMAN -->
<ng-template #modalEntrepreneurWomanContent let-modal>
  <div class="modal-body p-0">
    <div class="d-flex justify-content-center">
      <img
        class="img-fluid d-none d-lg-block"
        alt="disclaimer-preapproved-img"
        src="/assets/img/financing/entrepreneur-woman-desktop.png"
      />
      <img
        class="img-fluid d-block d-lg-none"
        alt="disclaimer-preapproved-img"
        src="/assets/img/financing/entrepreneur-woman-mobile.png"
      />
    </div>
    <h2 class="title my-3">Selecciona tu género</h2>
    <div class="mb-3 text-center mx-4">
      Esta información se usará para recomendarte <br class="d-none d-lg-block" />
      el crédito más adecuado para ti
      <img src="/assets/icons/emoji_sonrrojado.svg" alt="icon-image" />
    </div>
    <div class="btn-container d-flex">
      <button type="button" class="btn btn-modal" (click)="closeEntrepreneurWomanModal()">Mujer</button>
      <button type="button" class="btn btn-modal" (click)="dismissEntrepreneurWomanModal()">Hombre</button>
    </div>
    <img class="img-fluid mb-2 d-none d-lg-block" src="/assets/img/financing/woman-tcea.webp" alt="TCEA" />
    <div class="tcea-mobile-container d-flex d-lg-none">
      <img class="mb-2" src="/assets/img/financing/tcea/tcea-seed-desktop-financing.svg" alt="tcea_img" width="145" />
      <p class="termns">
        <span class="bold">
          Línea de crédito. TCEA máxima referencial calculada considerando una TEA de 109.83%, 
          aplicable para un monto de S/5,000 y un plazo de 12 meses, con una cuota de S/617.40, 
          que incluye el seguro de desgravamen de 0.76152% conforme al tarifario vigente 
          (el cliente puede optar por endosar el seguro, según requisitos de Mibanco). Tasa de 
          interés moratoria nominal anual de 15.3475%.
        </span><br>(*) Sujeto a evaluación crediticia. Mayor información de tasas, comisiones, gastos y demás condiciones en
        <a class="black sub" href="http://www.mibanco.com.pe">www.mibanco.com.pe</a>, 
        en la plataforma de atención al usuario de nuestra red de agencias o llamando a la banca telefónica al (01) 319-9999. 
        Mibanco - Banco de la Microempresa S.A. RUC: 20382036655.
      </p>
      <img class="mt-2" src="/assets/img/financing/compromiso-black.webp" alt="compromiso-img" width="62" />
    </div>
  </div>
</ng-template>
<ng-template #removedAllied>
  <img
    src="/assets/img/banner_aliados_general.png"
    alt="removed-allied-image"
    class="removed-allied-image"
    width="600px"
    height="600px"
    (click)="goToGeneralForm()"
  />
</ng-template>
