import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RefersFaqDetailModel, RefersFaqModel } from '@shared/models/refers.model';
import { DataLayerService } from '@shared/services/data-layer.service';
import { VIRTUAL_EVENT_VALUES_RE } from '@shared/constants/recommend.constants';


@Component({
  selector: 'landing-acoordion-refers',
  templateUrl: './landing-acoodion-refers.component.html',
  styleUrls: ['./landing-acoodion-refers.component.scss'],
})
export class LandingAcoordionRefersComponent {
  @Input() selectedFaq!: RefersFaqModel;
  selectedFaqChild: number | null = null;
  @Input() isVisible: boolean[] = [];
  @Output() questionClick = new EventEmitter<string>();

  constructor(private dataLayerService: DataLayerService){}

  public get faqDetails(): RefersFaqDetailModel[] {
    return this.selectedFaq?.details.slice().sort(
      (prev, curr) => prev.order - curr.order
    );
  }

  questionOnClick(index: number , title:string, title_second: string ) {
    this.dataLayerPush(title, title_second );
    this.isVisible[index] = !this.isVisible[index];
    for (let i = 0; i < this.isVisible.length; i++) {
      if (index != i) {
        this.isVisible[i] = false;
      }
    }
    if (this.isVisible[index]) {
      this.questionClick.emit(this.faqDetails[index].title);
    }
  }

  dataLayerPush(option: string, title_second: string): void {
    this.dataLayerService.virtualEventEnlaceRE(VIRTUAL_EVENT_VALUES_RE.questions+option + title_second ,VIRTUAL_EVENT_VALUES_RE.selectSectionAccordionBtnLanding);
  }
}