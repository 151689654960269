<div class="custom-input-group-content">
    <div class="custom-input-group-one " [ngClass]="{'error-border-one': error,'disabled-content': isDisabled}">
        <div class="text-dni">DNI</div>
        <img class="img-dni" src="/assets/img/seed-cash/inactive.svg" alt="">
    </div>
    <div class="custom-input-group" [ngClass]="{'error-border': error, 'not-error': !error,'disabled-content': isDisabled}">
        <div class="custom-input-container">
            <label for="{{dynamicId}}" class="custom-input-label" [class.active]="inputFocused || inputValue">
                {{ label }}
            </label>
            <input class="custom-input" [ngClass]="{'disabled-content': isDisabled}"  type="tel" [maxlength]="maxlength" [(ngModel)]="inputValue"
            (ngModelChange)="onInput($event)" (keypress)="numericOnly($event)" id="{{dynamicId}}" />
        </div>
    </div>
</div>