import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'milab-modal-readtips',
  templateUrl: './modal-readtips.component.html',
  styleUrls: ['./modal-readtips.component.scss']
})
export class ModalReadtipsComponent implements OnInit {
  isDisabled = true;
  constructor(public activeModal: NgbActiveModal) { }
  ngOnInit(): void {}
}
