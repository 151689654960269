import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export type Icon = {
  name: string;
  title: string;
  titleTwo: string;
  icon: string;
  url: string;
  description: string;
  section?: string;
  isActived: boolean;
  client?: boolean;
};

@Component({
  selector: 'landing-card-refers',
  templateUrl: './landing-card-refers.component.html',
  styleUrls: ['./landing-card-refers.component.scss']
})
export class LandingCardRefersComponent {
  @Input() title = '';
  @Input() titleMobile = '';
  @Input() description = '';
  @Input() icons: Icon[] = [];

  constructor(private router: Router) { }

  openRefers(icon: Icon): void {
    if(icon.url !== ''){
      this.router.navigate([icon.url]);
    }
  }
}