import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'milab-input-group-document',
  templateUrl: './input-group-document.component.html',
  styleUrls: ['./input-group-document.component.scss']
})
export class InputGroupDocumentComponent implements OnInit {
  @Input() label: string = '';
  @Input() maxlength: number = 8;
  @Output() inputChangeGroup = new EventEmitter<string>();
  @Input() isDisabled: boolean = false;
  @Input() valueDisabled: number = 0;
  @Input() dynamicId="custom-group";
  @Input() inputValue!: string;
  inputFocused: boolean = false;
  @Input() error: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  onFocus() {
    this.inputFocused = true;
  }

  onBlur() {
    this.inputFocused = false;
  }

  onInput(valor: Event) {
    this.inputChangeGroup.emit(this.inputValue);
    this.error = !this.inputValue || this.inputValue.toString().length < 8;
  }

  numericOnly(event: KeyboardEvent): boolean {
    const charCode = event.key.charCodeAt(0);
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
}