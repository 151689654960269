import { Component,Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VIRTUAL_EVENT_VALUES_RE } from '@shared/constants/recommend.constants';
import { TermsConditionsRefersLanding } from '@shared/interfaces/frontend/communities';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'modal-terms-landing-refers',
  templateUrl: './landing-modal-terms-refers.component.html',
  styleUrls: ['./landing-modal-terms-refers.component.scss']
})
export class ModalTermsLandingRefersComponent implements OnInit {
  loading!: boolean;
  @Input() terms!:TermsConditionsRefersLanding;
  totalBars: number[] = []
  bars = 3;
  constructor(public activeModal: NgbActiveModal, private dataLayerService: DataLayerService){}
  ngOnInit(): void {
    this.loading = false;
    this.totalBars = Array(this.bars).fill(0).map((_, index) => index);
    this.timeoutLoading();
  }

  timeoutLoading(): void {
    setTimeout(() => {
      this.loading = true;
    }, 1000);
  }
  public showResults(isContinue:boolean, type: string): void {
    this.dataLayerService.virtualEventEnlaceRE(VIRTUAL_EVENT_VALUES_RE.selectCloseModalTermsRecommendProgramPage,VIRTUAL_EVENT_VALUES_RE.selectSectionTermsAndCondition);
    this.activeModal.close({isContinue: isContinue, type: type});
  }

}
