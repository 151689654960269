 <div class="MobileCategoriesCta">
    <div class="banner_content">
      <div class="banner_information_desktop">
        <div class="banner_information_desktop_title animate__animated animate__fadeInLeft">
          <p class="title_standard desktop">Envía dinero a Venezuela, EEUU, Colombia</p>
          <p class="title_standard mobile">Envía dinero a Venezuela, EEUU, Colombia</p>
        </div>
        <div class="banner_information_desktop_description animate__animated animate__fadeInLeft">
          <p class="description">Y a más de 200 países en agencias Mibanco</p>
        </div>
        <div class="container-button animate__animated animate__fadeInUp">
          <button type="button" class="btn button-desktop" (click)="scrollTo('agences-remittance')">
            <span>Quiero enviar dinero</span>
          </button>
        </div>

        <div class="floating-button-mobile animate__animated animate__fadeInUp">
          <div class="floating-button" [class.fixed]="showButton">
            <button type="button" class="btn button-mobile" (click)="scrollTo('agences-remittance')">
              <span>Quiero enviar dinero</span>
            </button>
          </div>
        </div>
      </div>
      <div class="image-desktop animate__animated animate__fadeInRight">
        <img class="women-image-desktop" [src]="womenImageRoute" alt="women" loading="lazy" />
      </div>
      <div class="image-mobile animate__animated animate__fadeInRight">
        <div class="image">
          <img class="women-image-mobile" [src]="womenImageRoute" alt="women" loading="lazy" />
        </div>
      </div>
    </div>

    <div class="container">
      <remittances-best-option
        id="landing-card-refers"
        [title]="this.titleRemittance"
        [subtitle]="this.subtitleRemittance"
        [description]="this.descriptionRemittance"
        [titleMobile]="this.titleRemittanceMobile"
        [subtitleMobile]="this.subtitleRemittanceMobile"
        [icons]="this.icons"
      ></remittances-best-option>
    </div>
  </div>

<div id="agences-remittance" class="animate__animated animate__fadeInRight">
<shipping-to-five-agencies
  [selectedFaqButton]="selectedFaqButton"
  [isVisibleButton]="faqItemsVisibleButton"
></shipping-to-five-agencies>
</div>

<div id="send-money">
  <send-money
    id="send-money-remittance"
    [title]="this.titleRefers"
    [description]="this.descriptionRefers"
    [icons]="this.iconsFiveSend"
  ></send-money>
</div>

<div class="pad18">
  <shipping-cost></shipping-cost>
</div>

<frequent-questions [selectedFaq]="selectedFaq" [isVisible]="faqItemsVisible"></frequent-questions>
