<div class="container-send-money">
    <div class="title" [innerHTML]="title"></div>
    <div class="description"> {{description}} </div>
    <div class="icons">
        <div class="item-icon border-gradient" *ngFor="let icon of icons; let i = index" [ngClass]="{'bg-color-disabled': !icon.isActived}">
            <div class="icon"> 
                <img [src]='icon.icon' [alt]='icon.name' />
            </div>
            <div class="decription">
                <div class="number">{{i+1}}. </div>
                <div class="text" [innerHTML]="icon.description"></div>
            </div>
        </div>
    </div>
</div>