import { Component, HostListener, OnInit } from '@angular/core';
import { REMITTANCES_PROGRAM_VALUES } from '@shared/constants/remittances.contanst';
import { ButtonRemittancesModel, RemittancesModel } from '@shared/models/remittances.model';
import faqJson from '@shared/data/remittances/terms-conditions.json';
import remittancesJson from '@shared/data/remittances/map-google.json';
import { DataLayerService } from '@shared/services/data-layer.service';
import { VIRTUAL_EVENT_VALUES_REMITTANCES } from '@shared/constants/remittances.constant';


@Component({
  selector: 'remittances-landing',
  templateUrl: './remittances-landing.component.html',
  styleUrls: ['./remittances-landing.component.scss'],
})
export class RemittancesLandingComponent implements OnInit {
  showButton: boolean = false;
  titleRemittance = 'Mibanco es tu mejor opción para';
  subtitleRemittance = 'enviar dinero al exterior';
  descriptionRemittance = 'Conoce nuestros beneficios';
  titleRemittanceMobile = 'Mibanco es tu mejor opción';
  subtitleRemittanceMobile = 'para enviar dinero al exterior';
  icons = [
    {
      name: 'Tipo de cambio',
      description: 'competitivo',
      icon: '/assets/img/landing/remittances/money.svg',
      url: '',
      section: '',
      isActived: true,
    },
    {
      name: 'Seguridad garantizada',
      description: 'en agencias',
      icon: '/assets/img/landing/remittances/escudo.svg',
      url: '',
      section: '',
      isActived: true,
    },
    {
      name: 'Envío confiable del aliado',
      description: 'al país de destino',
      icon: '/assets/img/landing/remittances/map.svg',
      url: '',
      section: '',
      isActived: true,
    },
    {
      name: 'Atención',
      description: 'rápida y eficaz',
      icon: '/assets/img/landing/remittances/home.svg',
      url: '',
      section: '',
      isActived: true,
    },
  ];

  titleRefers = '¿Cómo enviar dinero al extranjero en Mibanco?';
  descriptionRefers = 'Podrás hacerlo en solo 4 pasos';
  iconsFiveSend = [
    {
      name: 'Paso 1:',
      description: '<strong>Acércate a una de las 5 agencias</strong> con tu documento de identidad.',
      icon: '/assets/img/landing/remittances/note.png',
      url: '',
      section: '',
      isActived:true,
    },
    {
      name: 'Paso 2:',
      description: 'Ten a la mano los <strong>datos personales del destinatario.</strong>',
      icon: '/assets/img/landing/remittances/card.png',
      url: '',
      section: '',
      isActived:true,
    },
    {
      name: 'Paso 3:',
      description: 'Realiza con nosotros <strong>el cambio de moneda del monto</strong> que enviarás.',
      icon: '/assets/img/landing/remittances/money-send.png',
      url: '',
      section: '',
      isActived:true,
    },
    {
      name: 'Paso 4:',
      description: 'Finalmente, pronto el dinero <strong>llegará a su destino.</strong>',
      icon: '/assets/img/landing/remittances/planet.png',
      url: '',
      section: '',
      isActived:true,
    },
  ];

  womenImageRoute: string = REMITTANCES_PROGRAM_VALUES.womanImageRoute;
  womenImageRouteImageBack: string = REMITTANCES_PROGRAM_VALUES.womenImageRouteImageBack;

  faqItemsVisible: boolean[] = [];
  selectedFaqId?: number;

  faqItemsVisibleButton: boolean[] = [];
  selectedFaqIdButton?: number;

  public get cardsFaq(): RemittancesModel[] {
    return this.faqData?.slice().sort((prev, curr) => prev.order - curr.order);
  }

  public get selectedFaq(): RemittancesModel | undefined {
    return this.faqData?.find((faq) => faq.contentManagerId === this.selectedFaqId);
  }

  public get cardsFaqButton(): ButtonRemittancesModel[] {
    return this.faqDataButton?.slice().sort((prev, curr) => prev.order - curr.order);
  }

  public get selectedFaqButton(): ButtonRemittancesModel | undefined {
    return this.faqDataButton?.find((faq) => faq.contentManagerId === this.selectedFaqId);
  }

  constructor(private dataLayerService: DataLayerService) {}

  ngOnInit(): void {
    this.faqData = faqJson.faq;
    this.selectedFaqId = this.cardsFaq[0].contentManagerId;

    this.faqDataButton = remittancesJson.faq;
    this.selectedFaqIdButton = this.cardsFaqButton[0].contentManagerId;
  }

  SendMoney() {}

  private faqData!: RemittancesModel[];

  private faqDataButton!: ButtonRemittancesModel[];

  scrollTo(elementId: string) {
    document.querySelector(`#${elementId}`)?.scrollIntoView({ behavior: 'smooth' });
    this.dataLayerPush(VIRTUAL_EVENT_VALUES_REMITTANCES.selectBannerRemittancesProgramPage);
  }

  dataLayerPush(labelOption:string): void {
    this.dataLayerService.virtualEventEnlaceREM(labelOption,VIRTUAL_EVENT_VALUES_REMITTANCES.selectSectionBanner);
  }

  @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: any) {
      const element = document.getElementById('five-agencies');
      if (element && window.scrollY >= 380) {
        element.classList.add('animate__animated', 'animate__fadeInRight');
      }

      const elementSendMoney = document.getElementById('send-money');
      if (elementSendMoney && window.scrollY >= 1000) {
        elementSendMoney.classList.add('animate__animated', 'animate__fadeInLeft');
      }
    }

}

