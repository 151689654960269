<div class="modal-share-header">
    <div class="modal-share-close">
        <img  class="close-link" (click)="activeModal.close()" src="/assets/img/intranet/refers/modal-close.svg" alt="arrow" >
    </div>
    <div class="modal-img-page">
        <img  class="" (click)="activeModal.close()" src="/assets/img/intranet/refers/noun-party.svg" alt="noun-party" >
    </div>
</div>
<div class="modal-body">
    <div class="card-share">
        <div class="card-share-title mt-4">¡Felicidades! Este es tu enlace de recomendado</div>
        <div class="card-share-description mb-2 mt-2">Compártelo con tus amistades para que registren su solicitud de financiamiento a través de este link único.</div>
        <div class="link-content">
            <div class="link mt-2">
              <div class="input-goup-content">
                <input type="text" 
                [readonly]="InputIsDisabled"
                class=" bg-color-input" 
                value="{{ truncateTextRecommend(shareLink , maxTitleLengthShareText) }}" 
                aria-describedby="basic-addon2">
                <span class="input-group-text copy-link-mobile" id="basic-addon2" (click)="copyLink()">
                    <img src="/assets/icons/refers/copy.svg" alt="copiar enlace"> <span class="copy_link">copiar</span> 
                </span>
              </div>
            </div>
        </div>
        <div class="social-networks">
            <ng-container *ngFor="let item of socialNetworks">
                <div class="item" *ngIf="validResize(item)">
                    <img class="image-social" [src]="item.icon" alt="{{ item.name }}" (click)="shareSocialNetworks(item.name)">
                    <label class="item-name" for="">{{ item.name }}</label>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="selected-copy" *ngIf="this.selectedCopyLink">Enlace copiado</div>