<header >
 <milab-header-refers class="fixed-header"></milab-header-refers>
</header>
<oigo-loading></oigo-loading>

<div *ngIf="!loading">
  <div class="dsc-banner-loader">
    <div class="dsc-banner-loader_content"></div>
    <div class="dsc-banner-loader_content_text">
        <div class="dsc-banner-loader_text" *ngFor="let item of this.totalBars;"></div>
        <div class="dsc-banner-loader_content_two"></div>
        <div class="dsc-banner-loader_text" *ngFor="let item of this.totalBars;"></div>
        <div class="dsc-banner-loader_content_two"></div>
        <div class="dsc-banner-loader_text" *ngFor="let item of this.totalBars;"></div>
    </div>
 </div>
</div>

<div class="content-temp" *ngIf="loading">
  <div class="banner_content">
    <div class="banner_information_desktop">
      <div class="banner_information_desktop_title">
        <p class="title_standard desktop">¿Quieres ganar una gift card de S/30?</p>
        <p class="title_standard mobile">¿Quieres ganar una gift card de S/30?</p>
      </div>
      <div class="banner_information_desktop_description">
        <p class="description">Si eres cliente<span class="b-name"> Mibanco</span> obtén tu enlace para recomendar a tus amistades a solicitar un préstamo desde la plataforma, si desembolsan <span class="b-name">¡Tú ganas!</span></p>
      </div>
      <div class="container-button">
        <button type="button" class="btn button-desktop" (click)="getCodeReferred()">
          <span>Obtener enlace</span>
        </button>
      </div>
      <div [class.fixed]="showButton">
        <div class="floating-button-mobile">
          <div class="floating-button">
            <button type="button" class="btn" [ngClass]="showButton ? 'button-mobile-flex' : 'button-mobile'"  (click)="getCodeReferred()">
              <span>Obtener enlace</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="image-desktop">
      <img class="women-image-desktop" [src]="womenImageRoute" alt="women" loading="lazy" />
    </div>
    <div class="image-mobile">
      <div class="image">
        <img class="women-image-mobile" [src]="womenImageRoute" alt="women" loading="lazy" />
      </div>
    </div>
  </div>
  
  <!-- section two -->
  
  <div class="container mt-4 ">
    <landing-card-refers
      id="landing-card-refers"
      [title]="this.titleRefers"
      [description]="this.descriptionRefers"
      [icons]="this.icons"
    >
    </landing-card-refers>
  </div>
  
  <!-- section three -->
  <landing-acoordion-refers [selectedFaq]="selectedFaq" [isVisible]="faqItemsVisible"> </landing-acoordion-refers>
  
  <div class="col-12 refers-ter">
    <div class="refers-ter-button mb-5 mt-5">
      <button (click)="openModalTerms()">Ver los Términos y Condiciones</button>
    </div>
  </div>
</div>
<footer>
  <yevo-footer></yevo-footer>
</footer>