<div class="container-landing-refers  animate__animated animate__fadeInLeft">
    <div class="title" [innerHTML]="title"></div>
    <div class="description"> {{description}} </div>
    <div class="icons">
        <div class="item-icon" *ngFor="let icon of icons" (click)='openRefers(icon)' [ngClass]="{'bg-color-disabled': !icon.isActived}">
            <div class="icon"> 
                <img class="img-card" [src]='icon.icon' [alt]='icon.name' />
            </div>
            <div class="namev2 __desktop " [ngClass]="{'color-disabled': !icon.isActived}"> {{icon.title}}</div>
            <div class="decription __desktop"> {{icon.description}} </div>
            <div class="content-description __mobile">
                <div class="namev2 " [ngClass]="{'color-disabled': !icon.isActived}"> {{icon.title}}</div>
                <div class="decription "> {{icon.description}} </div>
            </div>
        </div>
    </div>
</div>