export const NOTIFICATION = {
    maxTitleLength : 22,
  };
export const MEDAL_MAP: { [key: number]: string } = {
    1: 'Bronce',
    2: 'Plata',
    3: 'Oro',
  };

export const VIRTUAL_EVENT_VALUES_RE = {
    selectSectionCard1: 1,
    selectSectionCard2: 2,
    selectSectionCard3: 3,
    selectSectionBanner: 3,
    selectSectionBannerBtnLanding: 3,
    selectShareIconLinkCodeLanding: 4,
    selectShareIconLinkCodeLandingDni: 5,
    selectSectionAccordionBtnLanding: 6,
    selectSectionTermsAndCondition: 7,
    selectSectionModalWelcome: 6,
    selectShareLinkRecommendProgramPage: 'Seleccionar: Obtener enlace',
    selectApiRecommeded: 'No Cliente Mibanco',
    selectApiRecommededclient: 'Cliente Mibanco',
    selectModalLinkRecommendProgramPage: 'Seleccionar: Ver los Términos y Condiciones',
    selectCloseModalTermsRecommendProgramPage: 'Seleccionar: Entendido',
    selectCloseModalTermsRecommendProgramPage2: 'Ver los Términos y Condiciones',
    selectCloseCardAndBannerRecommendProgramPage: 'Seleccionar: Programa de Recomendados',
    selectApplyLoanRecommendProgramPage: 'Modal Bienvenida: solicita un préstamo',
    copyLink: 'Copiar enlace',
    shareLink: 'Modal Compartir: ',
    satisfactoryProgress:'Avance satisfactorio:',
    select:'Seleccionar:',
    questions:'Pregunta:',
    modalTerms:'Modal Condiciones:',
    modalWelcome:'Modal Bienvenida:',
    womanImageRoute: '/assets/img/landing/referido-banner-mujer.png',
}

export const REFERRED_PROGRAM_VALUES_RE = {
  urlShareWhatsApp: 'https://wa.me/?text=',
  urlShareWhatsAppWeb: 'https://web.whatsapp.com/send?text=',
  urlShareFacebook: 'https://www.facebook.com/sharer.php?u=',
  urlShareSms: 'sms:?body=',
  newRegisterRoute: '/financiamiento/solicitud?recommend_code=',
};