import { Component, HostListener, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialNetwork } from '@shared/interfaces/frontend/communities';
import socialNetworksJson from '@shared/data/communities/social-networks.json';
import { SOCIAL_NETWORKS_VALUES } from '@shared/constants/communities.constants';
import { DataLayerService } from '@shared/services/data-layer.service';
import { NOTIFICATION, VIRTUAL_EVENT_VALUES_RE, REFERRED_PROGRAM_VALUES_RE } from '@shared/constants/recommend.constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'landing-modal-share-link',
  templateUrl: './landing-modal-share-link-refers.component.html',
  styleUrls: ['./landing-modal-share-link-refers.component.scss']
})
export class LandingModalShareLinkRefersComponent implements OnInit {
  @Input() referredCode: string | undefined;
  @Input() source!: string | undefined;
  baseUrl = ''
  isMobile: boolean = false;
  constructor(public activeModal: NgbActiveModal,private dataLayerService: DataLayerService, private route: ActivatedRoute) { }
  shareLink = '';
  shareLinInfo = '';
  socialNetworks: SocialNetwork[] = [];
  selectedCopyLink: boolean = false;
  maxTitleLengthShareText: number = NOTIFICATION.maxTitleLength;
  info: string = '¡Hola! Solicita tu préstamo de manera digital hoy mismo. Realiza tu solicitud de préstamo usando este enlace único para la campaña: ';
  whatsappText = '%C2%A1Hola!%20Solicita%20tu%20pr%C3%A9stamo%20%F0%9F%92%B0de%20manera%20digital%20hoy%20mismo.%F0%9F%9A%80%20Realiza%20tu%20solicitud%20de%20pr%C3%A9stamo%20usando%20este%20enlace%20%C3%BAnico%20para%20la%20campa%C3%B1a%20%F0%9F%93%B2: ';
  InputIsDisabled: boolean = true;
  utm_source: string = '';
  utm_medium: string  = '';
  utm_campaign: string  = '';


  ngOnInit(): void {
    this.socialNetworks = socialNetworksJson;
    this.baseUrl = window.location.origin;
    this.route.queryParams.subscribe((params) => {
      this.utm_campaign = params.utm_campaign || '';
      this.utm_medium   = params.utm_medium || '';
      this.utm_source   = params.utm_source || '';
    });
    this.shareLinkOnInit( this.utm_source, this.utm_medium, this.utm_campaign);
    this.checkIfMobile();
  }

  shareLinkOnInit(utm_source: string, utm_medium: string, utm_campaign: string ) {
    if (utm_source || utm_medium || utm_campaign ) {
      this.shareLink = `${this.baseUrl}${REFERRED_PROGRAM_VALUES_RE.newRegisterRoute}${this.referredCode}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
    } else {
      this.shareLink = `${this.baseUrl}${REFERRED_PROGRAM_VALUES_RE.newRegisterRoute}${this.referredCode}`
    }
  }


  truncateTextRecommend(text: string, maxLength: number): string {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }
  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  validResize(item: any): boolean {
    return (this.isMobile && item.mobile) || (!this.isMobile && item.desktop);
  }

  copyLink() {
    this.dataLayerPush(VIRTUAL_EVENT_VALUES_RE.copyLink);
    navigator.clipboard
      .writeText(this.shareLink)
      .then(() => {
        this.selectedCopyLink = true;
        setTimeout(() => {
          this.selectedCopyLink = false;
        }, 2000);
      })
      .catch((err) => { });
  }

  shareSocialNetworks(name: string) {
    navigator.clipboard.writeText(this.shareLink).then();
    this.dataLayerPush(name);
    switch (name) {
      case SOCIAL_NETWORKS_VALUES.whatsApp: {
        const urlWsp = this.isMobile ? REFERRED_PROGRAM_VALUES_RE.urlShareWhatsApp : REFERRED_PROGRAM_VALUES_RE.urlShareWhatsAppWeb;
        const urlWhatsapp = `${urlWsp}${this.whatsappText}${encodeURIComponent(this.shareLink)}`;
        this.redirectAndShare(urlWhatsapp);
        break;
      }
      case SOCIAL_NETWORKS_VALUES.facebook: {
        const urlFacebook = `${REFERRED_PROGRAM_VALUES_RE.urlShareFacebook}${encodeURIComponent(this.shareLink)}`;
        this.redirectAndShare(urlFacebook);
        break;
      }
      case SOCIAL_NETWORKS_VALUES.sms: {
        const urlSms = `${REFERRED_PROGRAM_VALUES_RE.urlShareSms}${this.whatsappText}${encodeURIComponent(this.shareLink)}`;
        this.redirectAndShare(urlSms);
        break;
      }
    }
  }

  redirectAndShare(url: string): void {
    window.open(url, '_blank');
  }

  dataLayerPush(option: string): void {
    this.dataLayerService.virtualEventEnlaceRE(VIRTUAL_EVENT_VALUES_RE.shareLink + option, VIRTUAL_EVENT_VALUES_RE.selectShareIconLinkCodeLanding);
  }


}