import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export type Icon = {
  name: string;
  icon: string;
  url: string;
  description: string;
  section?: string;
  isActived: boolean;
  client?: boolean;
};

@Component({
  selector: 'send-money',
  templateUrl: './landing-remittances-send-money.component.html',
  styleUrls: ['./landing-remittances-send-money.component.scss']
})
export class LandingRemittancesSendMoneyComponent {

    @Input() title = '';
    @Input() description = '';
    @Input() icons: Icon[] = [];
  
    constructor(private router: Router) { }
  

}