import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecommendService } from '@shared/services/recommend.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { RefersFaqModel } from '@shared/models/refers.model';
import faqJson from '@shared/data/refers/refers.json';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferredProgram, TabManager, TermsConditionsRefersLanding } from '@shared/interfaces/frontend/recommend';
import termsAndConditionsJson from '@shared/data/landing/terms-and-conditions-landing-refers.json';
import { MEDAL_MAP, REFERRED_PROGRAM_VALUES } from '@shared/constants/communities.constants';
import { VIRTUAL_EVENT_VALUES_RE } from '@shared/constants/recommend.constants';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { ModalTermsLandingRefersComponent } from '@shared/components/landing-modal-terms-refers/landing-modal-terms-refers.component';
import { LandingModalShareLinkRefersComponent } from '@shared/components/landing-modal-share-link-refers/landing-modal-share-link-refers.component';
import { ModalGetcodeComponent } from '../components/modal-getcode/modal-getcode.component';
import { ModalReadtipsComponent } from '../components/modal-readtips/modal-readtips.component';

@Component({
  selector: 'milab-recommended-landing',
  templateUrl: './recommended-landing.component.html',
  styleUrls: ['./recommended-landing.component.scss']
})
export class RecommendedLandingComponent implements OnInit {
  womenImageRoute: string = VIRTUAL_EVENT_VALUES_RE.womanImageRoute;
  referredPrograma!: ReferredProgram;
  termsConditionsReferredLanding!: TermsConditionsRefersLanding;
  showButton: boolean = false;
  fixedPointInit: number = REFERRED_PROGRAM_VALUES.pointScrollYInit;
  parentRoute = '';
  selectedTab!: TabManager;
  user: any = '';
  tabItems: any = [];
  loading!: boolean;
  faqItemsVisible: boolean[] = [];
  selectedFaqId?: number;
  titleRefers = 'Sigue estos pasos para participar';
  descriptionRefers = '';
  isMenuMobileExpande: boolean = false;
  totalBars: number[] = []
  bars = 3;
  recommendedCode = '';
  icons = [
    {
      name: 'Paso 1:',
      title: '1. Comparte tu enlace único a tus recomendados',
      titleTwo: '',
      description: 'Valida tu DNI para obtener tu enlace único y enviarle a tus amistades.',
      icon: '/assets/img/intranet/refers/black-cohete.svg',
      url: '',
      section: '',
      isActived: true,
    },
    {
      name: 'Paso 2:',
      title: '2. Tu recomendado solicita su préstamo desde Mibanco Lab',
      titleTwo: '',
      description: 'Tus amistades deberán ingresar su solicitud de préstamo a través del enlace compartido.',
      icon: '/assets/img/intranet/refers/black-payment.svg',
      url: '',
      section: '',
      isActived: true,
    },
    {
      name: 'Paso 3:',
      title: '3. Ganas una giftcard de S/30 cuando desembolsan desde Mibanco',
      titleTwo: '¡tú ganas!',
      description: 'Si tu recomendado desembolsa el préstamo solicitado. ¡Recibirás una gift card virtual de S/30!',
      icon: '/assets/img/intranet/refers/black-gift-card.svg',
      url: '',
      section: '',
      isActived: true,
    }
  ];

  public get cardsFaq(): RefersFaqModel[] {
    return this.faqData?.slice().sort((prev, curr) => prev.order - curr.order);
  }

  public get selectedFaq(): RefersFaqModel | undefined {
    return this.faqData?.find((faq) => faq.contentManagerId === this.selectedFaqId);
  }

  constructor(
    private modalService: NgbModal,
    private dataLayerService: DataLayerService,
    private localStorageService: LocalStorageService,
    private recommendService: RecommendService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading = false;
    this.totalBars = Array(this.bars).fill(0).map((_, index) => index);
    this.faqData = faqJson.faq;
    this.selectedFaqId = this.cardsFaq[0].contentManagerId;
    this.parentRoute = this.activatedRoute.parent?.routeConfig?.path ?? '';
    this.isMenuMobileExpande = this.getScreenSize();
    this.timeoutLoading();
  }

  openModalTerms() {
    this.dataLayerService.virtualEventEnlaceRE(
      VIRTUAL_EVENT_VALUES_RE.selectCloseModalTermsRecommendProgramPage2,
      VIRTUAL_EVENT_VALUES_RE.selectSectionTermsAndCondition
    );
    const modalRef = this.modalService.open(ModalTermsLandingRefersComponent, {
      windowClass: 'modal-terms-conditions-v2',
    });
    this.termsConditionsReferredLanding = termsAndConditionsJson.referredProgram;
    modalRef.componentInstance.terms = this.termsConditionsReferredLanding;
  }

  getCodeReferred(){
  const modalRef = this.modalService.open(ModalGetcodeComponent, { windowClass: 'modal-document' });
  this.dataLayerPush(VIRTUAL_EVENT_VALUES_RE.selectShareLinkRecommendProgramPage);
    modalRef.result
    .then((result) => {
      if(result != null){
        this.getReadTipsBefore(result);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  openModalLink(referredCode:string) {
    const modalRef = this.modalService.open(LandingModalShareLinkRefersComponent, { windowClass: 'modal-share-link-v2' });
    modalRef.componentInstance.referredCode = referredCode;
    modalRef.componentInstance.source = this.parentRoute;
  }

  dataLayerPush(labelOption: string): void {
    this.dataLayerService.virtualEventEnlaceRE(labelOption, VIRTUAL_EVENT_VALUES_RE.selectShareIconLinkCodeLanding);
  }

  getReadTipsBefore(value:string){
    this.recommendedCode = value;
    const modalRef = this.modalService.open(ModalReadtipsComponent, { windowClass: 'modal-readtipsbefore', backdrop: 'static' });
      modalRef.result
      .then((result) => {
        this.openModalLink(this.recommendedCode);
      })
      .catch((error) => {
        console.log(error);
      });
    }

  timeoutLoading(): void {
    setTimeout(() => {
      this.loading = true;
    }, 2000);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() { 
    this.showButton = window.scrollY > this.fixedPointInit;
  }

  private faqData!: RefersFaqModel[];

  private redirectToDashboardRoute(url: string): void {
    this.router.navigate([`${url}`], { queryParamsHandling: 'merge' });
  }

  @HostListener('window:resize', ['$event'])
  private getScreenSize(): boolean {
    return window.innerWidth <= 768;
  }
}

