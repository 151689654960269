<div class="modal-share-header">
  <div class="icon-close">
    <span (click)="activeModal.close(null)"  class="icon">&times;</span>
</div>
    <h4 class="modal-share-title">Obtén tu enlace de recomendado</h4>

</div>
<div class="modal-body-get">
    <div class="card-shar-form">
        <p class="title-form">Ingresa tu DNI para validar tus datos como cliente</p>
        <form [formGroup]="frmRegister" (ngSubmit)="getRecaptchaToken()">
            <div>
              <milab-input-group-document 
                label="Nro. de documento"
                [isDisabled]="isDocumentNumber"
                [valueDisabled]="numberDocument"
                dynamicId="documentNumber"
                [error]="showError('documentNumber') || isDocumentNumberUps "
                (inputChangeGroup)="onInputChangeGroup($event)">
              </milab-input-group-document>
              <label for="documentNumber" class="form-inline-error rel" *ngIf="showError('documentNumber')">
                <div class="text-error text-error-float">
                  <div *ngIf="getErrorsFromField('documentNumber')?.required">Ingresa tu DNI.</div>
                </div>
              </label>
            </div>
            <div class="warning-document mb-2"*ngIf="isDocumentNumberUps">
                <div class="warning-document-img"><img src="/assets/img/intranet/refers/warning.svg" alt="warning" ></div>
                <div class="warning-document-title">Lo sentimos, por el momento, no cumples con los requisitos para participar de la campaña</div>
            </div>

            <div class="row mb-2">
              <div class="form-grou col-12">
                <div class="custom-control-v2 custom-checkbox">
                  <input type="checkbox"
                  class="form-control custom-control-input"
                  formControlName="acceptPersonalData"
                  id="acceptPersonalData"
                  (click)="acceptPersonalDataChecked($event)"
                  />
                  <label for="acceptPersonalData"  class="custom-control-label checkbox"
                       [ngClass]="{ 'check-error': showError('acceptPersonalData') }"></label>
                  <label class="text-check-input" for="term-one">He leído y entendido el
                    <a class="" (click)="openModalInformative('acceptPersonalData')">Consentimiento informado
                      para el Tratamiento de Datos Personales.</a>
                  </label>
                </div>
              </div>
            </div>
            
              <div class="row mb-3">
                <div class="form-grou col-12">
                  <div class="custom-control-v2 custom-checkbox">
                    <input type="checkbox"
                    class="form-control custom-control-input"
                    formControlName="acceptTermsConditios"
                    id="acceptTermsConditios"
                    (click)="acceptTermsConditiosChecked($event)"
                    />
                    <label  for="acceptTermsConditios" 
                            class="custom-control-label checkbox" 
                            [ngClass]="{ 'check-error': showError('acceptTermsConditios') }">
                    </label>
                    <label class="text-check-input" for="term-two">Acepto
                      <a class="" (click)="openModalInformative('acceptTermsConditios');"> Términos y Condiciones del Programa de Recomendados Mibanco.</a>
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <div class="container-fluid p-1 mb-2 meesage">
                  Este sitio está protegido por reCAPTCHA y se aplican la
                  <a href="https://policies.google.com/privacy">Política de privacidad</a> y los
                  <a href="https://policies.google.com/terms">Términos de servicio</a> de Google.
                </div>
              </div>

            <div class="register__submit ">
              <button [type]="'submit'"
                      [disabled]="!frmRegister.valid" 
                      [ngClass]="{'btn-active': frmRegister.valid,'btn-disabled': !frmRegister.valid}">Continuar
              </button>
            </div>
          </form>
    </div>
</div>
