import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { headerLinks, headerLinksLogin, menuData } from '@shared/data/dashboard/menu.json';
import { MenuDashboard } from '@shared/interfaces';
import { AuthService } from '@shared/services/auth.service';

@Component({
  selector: 'yevo-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  routerLinks: any = {
    default: headerLinks,
    logged: headerLinksLogin,
  };

  links: any = headerLinks;
  isMenuMobileExpande: boolean = false;
  menu: MenuDashboard[] = menuData;
  isUserLogged: boolean = false;
  currentRoute!: string;
  constructor(private authService: AuthService, private router: Router,private rutaActiva: ActivatedRoute) {}

  ngOnInit(): void {
    this.authService.isLogged$.subscribe((logged) => {
      this.links = this.getHeaderLinks(logged);
    });
    this.goToGeneralForm();
  }

  getHeaderLinks(isLogged: boolean) {
    if (isLogged) {
      this.isUserLogged = true;
      return this.routerLinks.logged;
    }
    return this.routerLinks.default;
  }

  mobileMenuIsExpanded(event: any) {
    if (event) {
      this.isMenuMobileExpande = true;
    } else {
      this.isMenuMobileExpande = false;
    }
  }

  public goToGeneralForm(): void {
    this.currentRoute = this.router.url;
  }
}
