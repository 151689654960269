import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTermsLandingRefersComponent } from '@shared/components/landing-modal-terms-refers/landing-modal-terms-refers.component';
import { DataLayerService } from '@shared/services/data-layer.service';
import { focusFormControl, formMarkAsTouched, isFormInvalid } from '@shared/utils/form.utils';
import { DOCUMENT_VALIDATORS } from '@shared/utils/validators.utils';
import termsAndConditionsJson from '@shared/data/landing/terms-and-conditions-landing-refers.json';
import { RecommendService } from '@shared/services/recommend.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ReferredRegisterRequest } from '@shared/interfaces/frontend/recommend';
import { LoaderService } from '@shared/services/loader.service';
import { VIRTUAL_EVENT_VALUES_RE } from '@shared/constants/recommend.constants';

@Component({
  selector: 'milab-modal-getcode',
  templateUrl: './modal-getcode.component.html',
  styleUrls: ['./modal-getcode.component.scss']
})
export class ModalGetcodeComponent implements OnInit {
  @Output() sendDataToParent = new EventEmitter<any>(); // Usa un tipo más específico en lugar de any si es posible

  frmRegister!: FormGroup;
  isDocumentNumber:boolean = false;
  numberDocument!: number;
  isDocumentNumberUps: boolean = false;
  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder, 
              private modalService: NgbModal,
              private elementReference: ElementRef,
              private dataLayerService: DataLayerService,
              private recommendService: RecommendService,
              private recaptchaV3Service: ReCaptchaV3Service,
              private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.initForm();
  }

  public getErrorsFromField(field: string): ValidationErrors | null | undefined {
    return this.frmRegister.get(field)?.errors;
  }

  public showError(field: string, dirty: boolean = false): boolean | undefined {
    const control = this.frmRegister.get(field)!;
    return dirty ? control.invalid && (control.dirty || control.touched) : control.invalid && control.touched;
  }

  onInputChangeGroup(event: string) {
    const documentNumber = event.toString().replace(/[^0-9]/g, '');
    this.updateFormControl('documentNumber', documentNumber);
  }

  private initForm(): void {
    this.frmRegister = this.formBuilder.group(
      {
        documentNumber: [null, DOCUMENT_VALIDATORS(true)],
        acceptPersonalData: [null, [Validators.required,Validators.requiredTrue]],
        acceptTermsConditios: [null, [Validators.required,Validators.requiredTrue]],
        recaptcha: [null],
      });
  }

  updateFormControl(name: string, value: number | string) {
    const control = this.frmRegister.get(name);
    control?.setValue(value);
    control?.markAsTouched();
  }

  acceptPersonalDataChecked(event: any) {
    if (event.target.checked) {
      this.frmRegister.get('acceptPersonalData')?.setValue(true);
    } else {
      this.frmRegister.get('acceptPersonalData')?.setValue('');
    }
  }

  acceptTermsConditiosChecked(event: any) {
    if (event.target.checked) {
      this.frmRegister.get('acceptTermsConditios')?.setValue(true);
    } else {
      this.frmRegister.get('acceptTermsConditios')?.setValue('');
    }
  }
  
  openModalInformative(type: string): void {
    if (type === 'acceptPersonalData') {
      this.openModalChecked(termsAndConditionsJson.acceptPersonalData,'modal-terms-conditions-v4');
      return;
    }
    this.openModalChecked(termsAndConditionsJson.referredProgram,'modal-terms-conditions-v3');
  }

openModalChecked(dataModal: any, windowClassString: string): void {
  const modalRef = this.modalService.open(ModalTermsLandingRefersComponent, {
    windowClass: windowClassString,
  });
  modalRef.componentInstance.terms = dataModal;
  modalRef.result.then((resp) => {
    if(resp != null){
      if(resp.isContinue){
        this.frmRegister.get(resp.type)?.setValue(true);
        return;
      }
        this.frmRegister.get(resp.type)?.setValue('');
    }

  })
  .catch((error) => {
    console.log(error);
  });
}


getRecaptchaToken(){
  this.recaptchaV3Service.execute('recommended').subscribe((token: string = '') => {
    this.frmRegister.get('recaptcha')?.setValue(token);
    this.nextStep();
  });
}

dataLayerPush(labelOption: string): void {
  this.dataLayerService.virtualEventEnlaceRE(labelOption, VIRTUAL_EVENT_VALUES_RE.selectShareIconLinkCodeLandingDni);
}

  nextStep(){
    const formKeys = Object.keys(this.frmRegister.controls);
    if (isFormInvalid(this.frmRegister, formKeys)) {
      focusFormControl(this.frmRegister, this.elementReference);
      formMarkAsTouched(this.frmRegister, true, formKeys);
      return;
    }
    this.loaderService.setIsLoadingLazyPage(true);
    const data:ReferredRegisterRequest = {
      userId: 0,
      documentType: "C",
      documentNumber:this.frmRegister.value.documentNumber,
      chkPersonalData: this.frmRegister.value.acceptPersonalData,
      chkTerms: this.frmRegister.value.acceptTermsConditios
    }

    this.recommendService.registerRecommendProgram(data,this.frmRegister.value.recaptcha).subscribe((response) => {
      this.loaderService.setIsLoadingLazyPage(false);
      if(response.eligible){
        this.activeModal.close(response.referredCode);
        this.isDocumentNumberUps = false;
        this.dataLayerPush(VIRTUAL_EVENT_VALUES_RE.selectApiRecommededclient);
      }else{
        this.dataLayerPush(VIRTUAL_EVENT_VALUES_RE.selectApiRecommeded);
        this.isDocumentNumberUps = true;
      }
    },
    (error) => {
      this.loaderService.setIsLoadingLazyPage(false);
      console.error("Error: ", error);
      this.isDocumentNumberUps = true;
    });
    
  }
}
