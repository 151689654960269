export enum APPROVED_TYPE_RESPONSES {
  NO_PRE_APPROVED = 0,
  APPROVED = 1,
  PRE_APPROVED_KUSHKA = 2,
  DIGITAL_PRE_APPROVED = 3,
  DIGITAL_APPROVED = 4,
  CDE = 6,
  FIRST_CREDIT = 7,
}

export enum DERIVATION_TYPES {
  SEEDCASH = 'Capital Semilla',
  EVALUATION = 'Evaluación solicitud de crédito',
  RECOMMENDED = 'Crédito Pre-aprobado',
  KUSHKA = 'Kushka',
  PREAPPROVED = 'preaprobado digital',
  DIGITAL_APPROVED = 'aprobado digital',
  CDE = 'compra de deuda',
  FIRST_CREDIT = 'mi primer crédito',
}

export enum TYPES_CARD_REQUEST {
  SEEDCASH = 0,
  EVALUATION = 1,
  RECOMMENDED = 2,
  KUSHKA = 3,
  PREAPPROVED = 4,
  DIGITAL_APPROVED = 5,
}

export const MARRIED_IDENTIFIER: string = 'Casad@';
export const MARRIED_IDENTIFIER_CODE: string = '29002';
export const MARRIED_IDENTIFIER_PREAPPROVED: string = 'Casad@ / Conviviente';

export enum REJECT_CODE_LIST {
  NEGATIVE_BASE_CODE = 1,
  HIGH_RISK_CODE = 2,
  MIN_AMOUNT_CODE = 3,
  SENT_SOLICITUDE_CODE = 4,
}

export enum FINANCING_TYPES {
  MESSAGE_USER = 'Por el momento no tenemos una oferta para ti',
  LOGIN_LABEL = 'Inicia Sesión',
  DASHBOARD_LABEL = 'Volver al panel',
  VALIDATE_SESSION = 'nuevo-intranet',
}
export const LOGIN_ROUTE: string[] = ['/ingresar'];
export const DASHBOARD_ROUTE: string[] = ['/nuevo-intranet/dashboard'];

export enum EMPLOYMENT_SITUATION_CODES {
  INDEPENDENT_CODE = '25002',
  DEPENDENT_CODE = '25001',
}
export enum EMPLOYMENT_SITUATION_LABES {
  INDEPENDENT_LABEL = 'Independiente',
  DEPENDENT_LABEL = 'Dependiente',
}

/** FINANCING REQUEST MESSAGE */
export enum MESSAGE_TYPES {
  SENT_MESSAGE = 'sent',
  NOT_SENT_MESSAGE = 'not-sent',
  ALREADY_SENT_MESSAGE = 'already-sent',
  FINANCING_REQUEST_REJECTED = 'financing-request-rejected',
  YAPE_NOT_OFFER = 'YAPE_NOT_OFFER',
  YAPE_REJECTED = 'YAPE_REJECTED',
  YAPE_NOT_OFFER_CLIENT = 'YAPE_NOT_OFFER_CLIENT',
  YAPE_NOT_CAMPAIGN = 'YAPE_NOT_CAMPAIGN',
  ALICORP_SEND_MESSAGE = 'ALICORP_SEND_MESSAGE',
}
export type messageTypes =
  | 'sent'
  | 'not-sent'
  | 'already-sent'
  | 'financing-request-rejected'
  | 'YAPE_NOT_OFFER'
  | 'YAPE_REJECTED'
  | 'YAPE_NOT_OFFER_CLIENT'
  | 'YAPE_NOT_CAMPAIGN'
  | 'ALICORP_SEND_MESSAGE';

export enum MARKS_PAGE_TYPES {
  SUCCESS_NOT_LOGGED = 'Éxito NL',
  SUCCESS_LOGGED = 'Éxito L',
  UNSUCCESS_NOT_LOGGED = 'No pasa NL',
  UNSUCCESS_LOGGED = 'No pasa L',
}

export enum BUTTON_MARKS_LABELS {
  CREATE_ACCOUNT = 'Crear mi cuenta',
  GO_HOME = 'Ir al inicio',
  FIND_AGENCIES = 'Ver agencias cercanas',
}

export enum STATE_MARKS_LABELS {
  REGISTRY_INTENTION = 'Intención de registro',
  SUCCESS_REGISTRY = 'Registro exitoso',
  NOT_AVAILABLE = '(not available)',
}

export const NEGATIVE_ERROR_CODE = 'thereIsAProblem';

export enum FinancingIncompleteStepsIndex {
  STEP_1 = 1,
  STEP_2 = 2,
  STEP_3 = 3,
  STEP_4 = 4,
  STEP_5 = 5,
  STEP_6 = 6,
  STEP_7 = 7,
}
export enum ApprovedIncompleteStepsLabel {
  STEP_4 = 'INFORMACION PERSONAL',
  STEP_5 = 'INFORMACION DEL NEGOCIO',
  STEP_6 = 'CARGA DE IMAGENES',
}
export enum PreapprovedIncompleteStepsLabel {
  STEP_4 = 'INFORMACION PERSONAL',
  STEP_5 = 'INFORMACION DEL NEGOCIO',
  STEP_6 = 'INFORMACION DECLARATIVA DE INGRESOS Y GASTOS',
  STEP_7 = 'CARGA DE IMAGENES',
}
export enum CDEIncompleteStepsLabel {
  STEP_4 = 'INFORMACION PERSONAL',
  STEP_5 = 'INFORMACION DEL NEGOCIO',
  STEP_6 = 'INFORMACION DECLARATIVA DE INGRESOS Y GASTOS',
  STEP_7 = 'CARGA DE IMAGENES',
}
export enum SeedCashIncompleteStepsLabel {
  STEP_4 = 'INFORMACION PERSONAL',
  STEP_5 = 'INFORMACION PERSONAL ADICIONAL',
  STEP_6 = 'INFORMACION LABORAL (DEPENDIENTE/INDEPENDIENTE)',
}
export enum EntrepreneurWomanIncompleteStepsLabel {
  STEP_1 = 'ELECCION DE GENERO',
  STEP_3 = 'INFORMACION PERSONAL',
  STEP_4 = 'DATOS DEL DOMICILIO/NEGOCIO',
}
export enum UberIncompleteStepsLabel {
  STEP_4 = 'LOGIN CON UBER POPUP',
  STEP_5 = 'LOGIN CON UBER',
  STEP_6 = 'INFORMACION PERSONAL',
  STEP_7 = 'INFORMACION DEL PRESTAMO',
}

export enum YapeInCompleteStepsLabel {
  STEP_1 = 'SELECCION DE CARD',
  STEP_2 = 'INFORMACION PERSONAL',
  STEP_3 = 'FORMULARIO DE SOLICITUD',
}

export enum NewSeedInCompleteStepsLabel {
  STEP_0 = 'FILTRO DE PERFIL DE USUARIO CON DNI',
  STEP_1 = 'INFORMACION PERSONAL',
  STEP_2 = 'FORMULARIO CUENTANOS',
  STEP_3 = 'INFORMACION LABORAL DEPENDIENTE',
  STEP_3_TAXI = 'INFORMACION LABORAL INDEPENDIENTE TAXI',
  STEP_3_COMERCE = 'INFORMACION LABORAL INDEPENDIENTE COMERCIO',
  STEP_3_DEFAUL = 'INFORMACION LABORAL INDEPENDIENTE OTROS GIROS',
  STEP_4 = 'INFORMACION NEGOCIO ',
  STEP_TAXI_4 = 'INFORMACION INGRESOS NEGOCIO TAXI',
  STEP_TAXI_5 = 'INFORMACION GASTO NEGOCIO TAXI',
  STEP_COMERCIO_4 = 'INFORMACION INGRESOS NEGOCIO COMERCIO',
  STEP_COMERCIO_5 = 'INFORMACION GASTO PERSONAL COMERCIO',
  STEP_5 = 'FINISH',
}

export enum NewSeedInCompleteStepsIndex {
  STEP_0 = '0',
  STEP_1 = '1',
  STEP_2 = '2',
  STEP_3 = '3',
  STEP_4 = '4',
  STEP_5 = '5',
  STEP_6 = '6',
  STEP_7 = '7',
}

export const googleAutocompleteOptions: google.maps.places.AutocompleteOptions = {
  componentRestrictions: { country: 'PE' },
  // !: Be careful to keep a few fields because Google charges us $$$ for each field that we request
  fields: ['address_components', 'geometry', 'formatted_address'],
};

export enum FORM_TYPES {
  GENERAL = 'GENERAL',
  WINGS = 'WINGS',
  SEEDCASH = 'SEMILLA',
  ALICORP = 'ALICORP',
  APPROVED = 'APROBADO-DIGITAL',
  PREAPPROVED = 'PRE-APROBADO',
  UBER = 'UBER',
  ENTREPRENEUR_WOMAN = 'CREDITO-MUJER',
  SAVINGS_ACCOUNT_BUSINESS = 'AHORRO-NEGOCIO',
  SAVINGS_ACCOUNT_FULL = 'FULL-AHORRO',
  YAPE = 'YAPE',
  CDE = 'CDE',
  FIRST_CREDIT = 'PRIMER-CREDITO',
  NEW_SEEDCASH = 'NEW-SEMILLA',
}

export enum STEPS_INDEX {
  STEP1_INDEX = 1,
  STEP2_INDEX = 2,
  STEP3_INDEX = 3,
}

export enum STEP_TITLES {
  STEP1 = 'paso1',
  STEP2 = 'paso2',
  STEP3 = 'paso3',
}

export const SKELETON = {
  barCode:4,
}